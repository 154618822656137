import React from "react";

import "react-select/dist/react-select.css";
import { getArticles } from "@shared/scripts/features/api";
import HomeCenterCard from "./HomeCenterCard";
import arrowImg from "right-arrow.png";
import circleArrowImg from "circle-right-arrow.png";
import ArrowHoverImg from "circle-right-arrow-hover.png";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      count: 0,
      xtLoading: true,
      hoveredIndex: null,
    };
    // this.ref = useRef(null);
    this.elementRef = React.createRef();
  }

  componentDidMount() {
    this.fetchArticles();
  }

  getTop = () => {
    const child = this.elementRef.current;
    const parent = document.querySelector(".home__center-right__list");
    const childRect = child.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    const topRelativeToParent = childRect.top - parentRect.top;
    return topRelativeToParent;
  };

  getHeight = () => {
    if (this.elementRef.current) {
      const { height } = this.elementRef.current.getBoundingClientRect();
      return height;
    }
    return 0;
  };

  // 计算时间差，并换算为对应格式
  timeDiff = publish => {
    const difference = Date.now() - Date.parse(publish);
    const seconds = difference / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const now = new Date(publish);
    const timeYear = now.getFullYear();
    const timeMonth = now.getMonth() + 1;
    const timeDay = now.getDate();
    const timeStr =
      seconds < 60
        ? "刚刚"
        : minutes < 60
        ? `${Math.floor(minutes)}分钟前`
        : hours < 24
        ? `${Math.floor(hours)}小时前`
        : days < 7
        ? `${Math.floor(days)}天前`
        : timeYear === new Date().getFullYear()
        ? `${timeMonth}月${timeDay}日`
        : `${timeYear}年${timeMonth}月${timeDay}日`;
    return timeStr;
  };

  fetchArticles() {
    getArticles().done(res => {
      this.setState({
        articles: res.articles,
        totalCount: res.publishedArticlesCount,
        elapsedDays: res.elapsedDays,
      });
    });
  }

  render() {
    const { articles, elapsedDays, totalCount, hoveredIndex } = this.state;
    return (
      articles &&
      articles.length > 0 && (
        <>
          <div className="home__center-left-header">
            <div className="home__center-left-title">
              <div>文章库</div>
              <p>
                已追踪 AI 发展 <span>{elapsedDays}</span> 天，
                <span>{totalCount}</span> 篇原创内容
              </p>
            </div>
            <div
              className="home__center-more"
              onClick={() => window.open(`/articles`, "_blank")}
            >
              <img
                className="home__center-more__arrow"
                src={circleArrowImg}
                alt="arrow"
              />
              <img
                className="home__center-more__hover"
                src={ArrowHoverImg}
                alt="arrow"
              />
            </div>
          </div>
          <div className="home__center-left__list">
            <div className="home__center-left__hover" />
            {articles.map((item, index) => (
              <HomeCenterCard
                key={item.id}
                type="left"
                hoveredIndex={hoveredIndex}
                index={index}
                initTop={8}
              >
                <div
                  className="home__article-item"
                  key={index}
                  ref={this.elementRef}
                  onMouseEnter={() => this.setState({ hoveredIndex: index })}
                  onMouseLeave={() => this.setState({ hoveredIndex: null })}
                  onClick={() => {
                    window.open(`/articles/${item.id}`, "_blank");
                  }}
                >
                  <div className="home__article-item__left">
                    <div className="home__article-item__title over--1">
                      {item.title}
                    </div>
                    <div className="home__article-item__content over--1">
                      {item.content}
                    </div>
                    <div className="home__article-item__bottom">
                      <div className="home__article-item__time">
                        {this.timeDiff(item.publishedAt)}
                      </div>
                      {item.tagList && item.tagList.length > 0 && (
                        <div className="home__article-item__tags">
                          {item.tagList.map((tag, index) => (
                            <div
                              className="home__article-item__tag-item"
                              key={index}
                            >
                              {tag}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <img
                    className="home__article-item__right"
                    src={item.coverImageUrl}
                    alt="img"
                  />
                </div>
              </HomeCenterCard>
            ))}
          </div>
          <div
            className="home__center-left__more"
            onClick={() => window.open(`/articles`, "_blank")}
          >
            进入文章库，查阅过往文章
            <img src={arrowImg} alt="arrow" />
          </div>
        </>
      )
    );
  }
}

export default Articles;
